@tailwind base;

@layer base {
	* {
		margin: 0;
		padding: 0;
	}

	.hyphens {
		/* These are technically the same, but use both */
		overflow-wrap: break-word;
		word-wrap: break-word;

		-ms-word-break: break-all;
		/* This is the dangerous one in WebKit, as it breaks things wherever */
		word-break: break-all;
		/* Instead use this non-standard one: */
		word-break: break-word;

		/* Adds a hyphen where the word breaks, if supported (No Blink) */
		-ms-hyphens: auto;
		-moz-hyphens: auto;
		-webkit-hyphens: auto;
		hyphens: auto;
	}

	.hyphens-manual {
		hyphens: manual;
	}

	html {
		@apply antialiased font-body;
		text-rendering: optimizeLegibility;
		scroll-behavior: smooth;
	}

	#__next {
		min-height: 100vh;
		@apply flex flex-col;
	}

	.huge {
		@apply font-display text-mobile-huge md:text-tablet-huge lg:text-desktop-huge leading-mobile-huge md:leading-tablet-huge lg:leading-desktop-huge;
		font-weight: 900;
	}

	h1,
	.h1 {
		@apply font-display text-mobile-h1 md:text-tablet-h1 lg:text-desktop-h1 leading-mobile-h1 md:leading-tablet-h1 lg:leading-desktop-h1;
		font-weight: 900;
	}

	h2,
	.h2 {
		@apply font-display text-mobile-small leading-mobile-small xs:text-mobile-h2 md:text-tablet-h2 lg:text-desktop-h2 xs:leading-mobile-h2 md:leading-tablet-h2 lg:leading-desktop-h2;
		font-weight: 900;
	}

	h3,
	.h3 {
		@apply font-display text-mobile-h3 md:text-tablet-h3 lg:text-desktop-h3 leading-mobile-h3 md:leading-tablet-h3 lg:leading-desktop-h3;
		font-weight: 900;
	}

	h4,
	.h4 {
		@apply font-body text-body-medium xs:text-mobile-h4 md:text-tablet-h4 lg:text-desktop-h4 xs:leading-mobile-h4 md:leading-tablet-h4 lg:leading-desktop-h4;
		font-weight: 400;
	}

	h5,
	.h5 {
		@apply font-body text-h5 leading-h5;
		font-weight: 700;
	}

	h1,
	h2,
	h3 {
		hyphens: auto;
	}

	div,
	p,
	.body-medium {
		@apply font-body text-body-medium leading-body-medium;
		font-weight: 400;
	}

	.body-small {
		@apply font-body text-body-small leading-body-small;
		font-weight: 400;
	}

	.body-xs {
		@apply font-body text-body-xs leading-body-xs;
		font-weight: 400;
	}

	.body-large {
		@apply font-body text-mobile-body-large leading-mobile-body-large md:text-tablet-body-large md:leading-tablet-body-large lg:text-desktop-body-large lg:leading-desktop-body-large;
		font-weight: 400;
	}
}

@tailwind components;

@layer components {
	.text-body-large {
		@apply font-body text-mobile-body-large md:text-tablet-body-large lg:text-desktop-body-large;
	}
	.header-offset {
		margin-top: theme('headerHeight');
	}
	.mt-headline {
		@apply mt-20;
	}
	.mb-page {
		@apply mb-24 md:mb-40 lg:mb-60;
	}
	.onboarding-headline {
		@apply w-full max-w-3xl px-8 mx-auto mb-16 text-center h3;
		hyphens: none;
	}
	.onboarding-content {
		@apply w-full max-w-lg px-8 mx-auto mb-20;
	}
	.onboarding-field-group-label {
		@apply mt-3 mb-3 text-action-red-shade;
	}
	.onboarding-field-multiple-wrapper {
		@apply flex gap-4;
	}

	.patient-content-area-small {
		@apply w-full max-w-md px-8 mx-auto;
	}

	.patient-content-area-medium {
		@apply w-full max-w-4xl px-8 mx-auto;
	}

	.patient-content-area-large {
		@apply w-full px-8 mx-auto max-w-7xl;
	}

	.support-content-area-medium {
		@apply w-full max-w-4xl px-8 mx-auto;
	}

	.rich-text-large p {
		@apply font-body text-mobile-body-large leading-mobile-body-large md:text-tablet-body-large md:leading-tablet-body-large lg:text-desktop-body-large lg:leading-desktop-body-large;
		font-weight: 400;
	}

	.front-page-custom-hero-text--en {
		@apply font-display text-mobile-huge leading-mobile-huge sm:text-mobile-huge md:text-tablet-large lg:text-desktop-large sm:leading-mobile-huge md:leading-tablet-huge lg:leading-desktop-huge;
	}
	.front-page-custom-hero-text--de {
		@apply font-display text-mobile-h1 leading-mobile-h1 sm:text-mobile-huge md:text-tablet-large lg:text-desktop-large sm:leading-mobile-huge md:leading-tablet-huge lg:leading-desktop-huge;
	}
}

@tailwind utilities;
@layer utilities {
	.col-count-1 {
		column-count: 1;
	}
	.col-count-2 {
		column-count: 2;
	}
	.button-small {
		@apply rounded-full h-9 font-body text-button-small;
		min-width: 40px;
	}

	.button-medium {
		@apply rounded-full font-body text-button-medium h-11;
		min-width: 44px;
	}
	.button-large {
		@apply rounded-full font-body text-button-large h-14;
		min-width: 56px;
	}

	.icon-small {
		width: 16px;
		height: 16px;
	}
	.icon-medium {
		width: 20px;
		height: 20px;
	}
	.icon-large {
		width: 24px;
		height: 24px;
	}
	.layout-padding-horizontal {
		@apply px-7 lg:px-12;
	}
	.layout-padding-negation {
		@apply -mx-7 lg:-mx-12;
	}
	.grid-layout {
		@apply grid grid-cols-4 gap-5 md:grid-cols-12 md:gap-6;
	}
	.header-height {
		height: theme('headerHeight');
	}
}

.bold {
	font-weight: bold;
}

.visually-hidden {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}

button:disabled {
	opacity: 0.5;
	cursor: default;
}

.slick-slider {
	user-select: text !important;
}

.button-small:not([class*='font-normal']),
.button-medium:not([class*='font-normal']),
.button-large:not([class*='font-normal']) {
	@apply font-bold;
}

.button-small[class*='w-'],
.button-medium[class*='w-'],
.button-large[class*='w-'] {
	min-width: unset !important;
}

/* Fix for RadioGroup helper text left magin */
fieldset .MuiFormHelperText-root {
	margin-left: 0;
}

/* Overrides for React BigCalendar */

/* remove AllDay view */
.rbc-allday-cell {
	display: none !important;
}

.rbc-time-view .rbc-header {
	border-bottom: none !important;
}

button > svg.icon {
	fill: #17445b !important;
	transition: fill 300ms;
}

button:hover > svg.icon {
	fill: white !important;
}

.table-scroll ::-webkit-scrollbar {
  height: 4px;
}

.table-scroll ::-webkit-scrollbar-track {
  background: #CEE9EF;
}

.table-scroll ::-webkit-scrollbar-thumb {
  background: #00AACE;
  border-radius: 2px;
}

.table-scroll .table-cell {
  font-size: 14px;
}

.table-scroll .table-header {
  font-weight: 600;
}
